import React from "react";
export default function DiscountList() {
  return (
    <div className="discounts-list">
      <div className="discount-container">
        <div className="hello">
          {" "}
          <div className="discount-title">Peter Gift</div>
          <div className="discount-offer">Buy 2 get 3 drinks offer</div>
        </div>

        <div>
          <div className="precent">40%</div>
        </div>
      </div>

      <div className="discount-container">
        <div className="hello">
          {" "}
          <div className="discount-title">Peter Gift</div>
          <div className="discount-offer">Buy 2 get 3 drinks offer</div>
        </div>

        <div>
          <div className="precent">40%</div>
        </div>
      </div>

      <div className="discount-container">
        <div className="hello">
          {" "}
          <div className="discount-title">Peter Gift</div>
          <div className="discount-offer">Buy 2 get 3 drinks offer</div>
        </div>

        <div>
          <div className="precent">40%</div>
        </div>
      </div>

      <div className="discount-container">
        <div className="hello">
          {" "}
          <div className="discount-title">Peter Gift</div>
          <div className="discount-offer">Buy 2 get 3 drinks offer</div>
        </div>

        <div>
          <div className="precent">40%</div>
        </div>
      </div>
    </div>
  );
}
