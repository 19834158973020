import React from 'react'
import Header from '../components/Header'
import Nav from '../components/nav'

export default function InfoPage() {
  return (
    <>
    <Header/>
     <div>InfoPage</div>
     
    <Nav/>
    </>
   
  )
}
