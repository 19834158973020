import React from 'react'

export default function DiscountDescription() {
  return (
    <div>Use your points to get discounts in many stores!
       <br/>
       <br/>
       <br/>
    </div>
   
  )
}
