import React from 'react'
import ConfirmLocation from '../components/ConfirmLocation'
import Header from '../components/Header'
import Nav from '../components/nav'

export default function Confirm() {
  return (
    <div>
<Header/>
<ConfirmLocation/>
<Nav/>
    </div>
  )
}
